import React from 'react'
import { graphql } from 'gatsby'
import CabaretLayout from '../../components/cabaretlayout'
import { StaticImage } from 'gatsby-plugin-image'
import CabaretSponsors from '../../slices/CabaretSponsors'

// TODO
//
// Background fadeout
const Cabaret = ({ data }) => (
    <CabaretLayout>

        <div className="cabaret-home w-[1920] max-w-[100%] justify-center mx-auto">

            {/* HEADER */}
            <center>
                <div className="top-image text-center w-full px-8">
                    <a href="/cabaret">
                        <StaticImage alt='Cabaret Festif! - 13e édition' className="max-w-[1856px] max-h-[1044px]" src="../../images/cabaret/header.png" />
                    </a>
                </div>
            </center>

            {/* MID-PAGE SECTION */}
            <div className='cabaret-home-mid-page-section'>

                {/* FIRST ROW */}
                <div className='cabaret-home-mid-page-section1'>
                    <div className='cabaret-home-mid-page-section1-text'>
                        <h1 className='text-sm'>La 13e édition<br/>du Cabaret Festif!</h1>
                        <h3>Le Cabaret Festif!, volet concours du festival Le Festif! est né en 2011, suite à la deuxième édition du festival. Croyant fermement en la diffusion de la musique nouvelle en région et prônant une offre culturelle diversifiée à l’année longue, l’équipe du Festif ! s’est inspirée des plus grands concours musicaux pour en créer un à l’image de son public curieux et affamé de nouveauté. Le Cabaret Festif! est destiné aux auteur.es-compositeur.es-interprètes ayant envie de se lancer dans le vide et de se faire découvrir par un public nouveau et différent de celui des grands centres. Ce public, ce sont les mélomanes aguerri-es de Charlevoix, souvent oublié-es par l’offre culturelle de la région mais fidèles et impatient-es de faire la découverte, hiver après hiver, des secrets musicaux les mieux gardés (pour le moment!) du Québec, grâce aux 4 spectacles présentés par le Cabaret Festif!</h3>
                    </div>
                    <div className='cabaret-home-mid-page-section1-image'>
                        <StaticImage alt='Publication instagram' className="max-w-[1856px] max-h-[1044px]" src="../../images/cabaret/home-mid-page-image1.png" />
                    </div>
                </div>

                {/* SECOND ROW */}
                <div className='cabaret-home-mid-page-section2 flex flex-col md:flex-row'>
                    <div className='cabaret-home-mid-page-section2-text md:order-2'>
                        <h1>Les avantages</h1>
                        <ul className="list-disc">
                            <li><h3>Le Cabaret Festif! est le seul concours au Québec à rémunérer ses candidat-es & à offrir l’inscription gratuite.</h3></li>
                            <li><h3>Les projets participants courent la chance de remporter des bourses en argent totalisant 15 000$.</h3></li>
                            <li><h3>La grande diversité des prix offerts aux candidat-es constitue un coffre d’outils complet pour le démarrage d’une carrière en musique (formations, séjours de création, matériel sonore, temps de studio, etc).</h3></li>
                            <li><h3>Grâce à nos partenaires diffuseurs, les candidat-es courent la chance de se produire sur les plus grandes scènes du Québec au cours de l’année suivant leur participation.</h3></li>
                            <li><h3>Les 12 projets choisis jouissent d’une visibilité majeure offerte par plusieurs médias de partout dans la province, leur donnant ainsi la chance de se faire connaître bien au-delà du concours.</h3></li>
                        </ul>
                    </div>
                    <div className='cabaret-home-mid-page-section2-image md:order-1'>
                        <StaticImage alt='Publication instagram' className="max-w-[1856px] max-h-[1044px]" src="../../images/cabaret/home-mid-page-image2.png" />
                    </div>
                </div>

            </div>

            {/* VIDEO */}
            <div>
                <video loop controls autoPlay muted>
                    <source src="https://le-festif-ca.cdn.prismic.io/le-festif-ca/Zjdki0MTzAJOCiiX_LeFestif_CabaretFestif2024.mov" type="video/quicktime" />
                </video>
            </div>

            <div className='mt-12 md:mx-36 mx-9 flex justify-center'>
                <CabaretSponsors data={data}/>
            </div>

        </div>
    </CabaretLayout>
)

export default Cabaret

export const cabaretSponsorResult = graphql`
query {
    allPrismicCabaretsponsor {
        edges {
          node {
            data {
              image {
                alt
                gatsbyImageData
              }
              link {
                url
              }
              name {
                text
              }
              category
              priority
            }
          }
        }
      }
    }
`
